import styled from 'styled-components';

import {
  device
} from '../../../utils';

export const Wrapper = styled.article `
  width: 100%;
  color: #111111;
  margin-bottom: 50px;

  .section-title {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0px 25px 0;

    @media ${device.laptop} {
      font-size: 35px;
    }
  }

  p {
    font-size: 13px;

    @media ${device.laptop} {
      font-size: 14px;
    }
  }

  .simple-link {
    font-size: 16px;
    padding: 10px 0;

    @media (hover: hover) {
      padding: 0;
    }
  }
`;


export const MobilePlaceholder = styled.div `
  display: block;
  width: 100%;
  padding-bottom: 100%;
  margin: 0;

  @media ${device.laptop} {
    display: none;
  }
`;