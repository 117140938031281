import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: 1000;

  padding: 15px 0 15px 0;

  backdrop-filter: saturate(110%) blur(2px);
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.15);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.tablet} {
      flex-direction: row;
      align-items: center;
    }
  }

  h1 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  h2 {
    font-size: 12px;
    line-height: 1.3em;
    font-weight: normal;
    margin: 0;
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 5px;

    .mif {
      display: flex;
      align-items: center;
      margin-left: 15px;
      display: none;

      img {
        margin-right: 5px;
      }

      .text {
        
      }
    }

    
    .buy {
      margin: 0 0 0 20px;
      border-radius: 30px;
      background-color: #c80f05;
      padding: 8px 20px;

      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      text-align: center;
      color: #ffffff;
      line-height: 1.2em;
      transition: background-color 300ms;

      &:hover {
        background-color: #AF0D04;
      }
    }
  }

  @media ${device.tablet} {
    .right {
      margin-top: 0px;
    }
  }

  @media ${device.desktop} {
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 14px;
    }
    .right {

      .mif {
        display: flex;
      }
    }
  }
`;