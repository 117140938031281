import React from 'react';
import { useIntersection } from 'react-use';
import csx from 'classnames';

import { Icon } from '../../../components/icons';
import MagicLink from '../../../components/link';

import StarIcon from '../../../icons/Star.svg';
import { nl2br } from '../../../utils';

import {
  Wrapper
} from './Zone4.styles';

const visibilityThreshold = [0, 1];

const Zone4 = (props) => {
  const {
    title,
    description,
    buy_link,
    buy_link_name,
  } = props;
  const ref = React.useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: visibilityThreshold
  });

  const showDots = intersection?.intersectionRatio > 0.7;

  return (
    <Wrapper>
      <div
        ref={ref}
        className="content container"
      >
        <div className="section-title">{title}</div>
        <div
          className={csx('stars', {
            'animate': showDots
          })}
        >
          {[...new Array(5)].map((_, idx) => (
            <Icon
              key={idx}
              src={StarIcon}
              width={20}
            />
          ))}
        </div>
        <p className="desc">{nl2br(description)}</p>
        {!!(buy_link?.url && buy_link_name) && (
          <div className="links">
            <MagicLink
              url={buy_link?.url}
              className="simple-link red"
            >
              {buy_link_name}
            </MagicLink>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default Zone4;
