import styled from 'styled-components';

// import { device } from '../../../utils';

export const Wrapper = styled.section`
  padding-top: 80px;
  padding-bottom: 50px;
  text-align: center;

  @keyframes pulse {
    0% {
      transform: scale3d(0.7, 0.7, 0.7);
    }
    50% {
      transform: scale3d(1.3, 1.3, 1.3);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

  .content {
    .section-title {
      font-family: 'Montserrat';
      font-size: 20px;
      margin-bottom: 10px;
    }

    .stars {
      > svg {
        margin-right: 5px;
        transform: scale3d(0.7, 0.7, 0.7);
      }

      &.animate {
        > svg {
          animation-name: pulse;
          animation-timing-function:ease-out;
          animation-duration: .4s;
          animation-fill-mode: forwards;
          

          &:nth-child(2) {
            animation-delay: 150ms;
          }
          &:nth-child(3) {
            animation-delay: 300ms;
          }
          &:nth-child(4) {
            animation-delay: 450ms;
          }
          &:nth-child(5) {
            animation-delay: 600ms;
          }
        }
      }
    }

    p.desc {
      font-size: 25px;
      max-width: 400px;
      margin: 20px auto 0 auto;
    }

    .links {
      margin-top: 20px;
    }
  }
`;