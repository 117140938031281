import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.section`
  position: relative;

  .cover {
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: rgb(250, 250, 250);

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .greyzone {
    padding-left: 0;
  }
`;

export const Content = styled.div`
  position: relative;
  background: #fafafa;
  background: linear-gradient(
    107deg,
    rgba(250, 250, 250, 0.9) 0%,
    rgba(250, 250, 250, 0.9) 75%,
    rgba(250, 250, 250, 0) 75%,
    rgba(255, 255, 255, 0) 100%
  );

  width: 70%;
  padding-left: 20px;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    bottom: 0;
    top: 0;
    width: calc(50vw - (1440px / 2));
    background: rgba(250, 250, 250, 0.9);
  }

  .shape {
    display: none;
  }

  .text {
    padding-top: 50px;
    padding-bottom: 50px;

    .logo {
      display: inline-block;
      margin-bottom: 15px;
      max-width: 80%;

      img {
        object-fit: contain !important;
      }
    }

    .section-title {
      margin-top: 0;
      line-height: 1.1em;
      padding-right: 70px;
      font-size: 17px;
      margin-bottom: 0;
    }

    p {
      padding-right: 20px;
    }
  }

  @media ${device.tablet} {
    max-width: 450px;
    padding-left: 20px;

    &,
    &.has-logo {
      height: 400px;
    }

    .shape {
      display: block;
      shape-outside: polygon(100% 0%, 83% 38%, 41% 100%, 100% 100%);
      shape-margin: 0px;
      width: 100%;
      height: 100%;
      float: right;
    }

    .text {
      padding-top: 80px;
      padding-bottom: 0;

      .section-title {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    &.has-logo {
      .text {
        padding-top: 60px;
      }
    }
  }

  @media ${device.laptop} {
    max-width: 550px;
    padding-left: 20px;

    &,
    &.has-logo {
      height: 520px;
    }

    .text {
      padding-top: 120px;
      padding-bottom: 0;
    }

    &.has-logo {
      .text {
        padding-top: 100px;
      }
    }
  }

  @media ${device.laptopM} {
    &,
    &.has-logo {
      height: 550px;
    }
  }
`;
