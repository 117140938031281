import React from 'react';
import MagicLink from '../../../components/link';
import { Imgix } from '../../../utils';

import { Wrapper } from './Zone2.styles';

const rightBackgroundResponsive = {
  xs: 10,
  md: 6,
};

const Zone2 = (props) => {
  const {
    title,
    points,
    background,
    buy_link,
    buy_link_name,
    link,
    link_name,
  } = props;
  return (
    <Wrapper className="container">
      <div className="left">
        <h2 className="section-title">{title}</h2>
        <div className="points">
          {points.map((point, idx) => (
            <div key={idx} className="point">
              <div className="image">
                <Imgix
                  {...point.image}
                  width={60}
                  height={60}
                  noCompress
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
              </div>
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: point.text.html }}
              />
            </div>
          ))}
        </div>
        <div className="links">
          {!!(link?.url && link_name) && (
            <MagicLink {...link} className="simple-link black">
              {link_name}
            </MagicLink>
          )}
          {!!(buy_link?.url && buy_link_name) && (
            <MagicLink url={buy_link.url} className="simple-link red">
              {buy_link_name}
            </MagicLink>
          )}
        </div>
      </div>
      <div className="right">
        {background ? (
          <Imgix
            {...background}
            sizes={rightBackgroundResponsive}
            className="background"
            noCompress
            imgStyle={{
              objectFit: 'contain',
            }}
          />
        ) : null}
      </div>
    </Wrapper>
  );
};

export default Zone2;
