import React from 'react';

import MagicLink from '../../../components/link';
import { useLocale } from '../../../services/i18n/useLocale';
// import MIFIcon from '../../../images/eshop/madeinfr.png';
import { getLocaleSettings } from '../../../utils/links';

import trads from '../trads';

import { Wrapper } from './Header.styles';

const Header = ({ title, category, productUrl }) => {
  const intl = useLocale();
  const settings = getLocaleSettings(intl.locale);

  return (
    <Wrapper>
      <div className="container">
        <h1>Ipone {title}</h1>
        <div className="right">
          <h2>{category}</h2>
          {/* <div className="mif">
            <img
              src={MIFIcon}
              height="17px"
              alt="Made in france"
            />
            <span className="text">
              {intl.formatMessage(trads.madeInFrance)}
            </span>
          </div> */}
          {settings.shop && (
            <MagicLink url={productUrl} className="button buy clear">
              {intl.formatMessage(trads.buy)}
            </MagicLink>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
