import React from 'react';
import { graphql } from 'gatsby';

import Page from '../../screens/eshop/premium';
import Seo from '../../components/seo';

import { getProductUrl, Product } from '../../utils';
import usePreviewData from '../../utils/usePreviewData';
import { useLocale } from '../../services/i18n/useLocale';

const zoneReg = new RegExp(/^z([0-9+])_(.*)/i);

const PremiumPage = ({ data: rawData, pageContext }) => {
  const intl = useLocale();
  const data = usePreviewData(rawData);
  const {
    prismicPremium: { data: prismicDatas },
    product: productRaw,
  } = data;

  console.log(pageContext);
  console.log(data);

  const product = Product(productRaw, intl.locale);
  const productUrl = getProductUrl(
    product.handle,
    pageContext.locale,
    product.defaultVariant
  );

  const prismic = Object.keys(prismicDatas).reduce(
    (r, key) => {
      let value = prismicDatas[key];

      // Nullify empty image
      if (
        value &&
        value.url !== undefined &&
        (value.url === null || value.url === '')
      ) {
        value = null;
      }
      const zoneMatch = key.match(zoneReg);

      if (!zoneMatch) {
        r[key] = value;
        return r;
      }

      const index = parseInt(zoneMatch[1], 10);
      if (!r.zones[index]) {
        r.zones[index] = {};
      }

      r.zones[index][zoneMatch[2]] = value;

      return r;
    },
    {
      zones: {},
    }
  );
  prismic.sprites_ratio = 0;
  prismic.sprites = prismic.body.map((sprite) => {
    if (!prismic.sprites_ratio) {
      const { width, height } = sprite.primary.image?.dimensions || {};
      prismic.sprites_ratio = width / height;
    }

    return {
      image: sprite.primary.image,
      hideLoad: sprite.primary.hide_load,
      anims: sprite.items || [],
    };
  });
  prismic.sprites_margin = {
    top: prismic.sprites_margintop || 5,
    bottom: prismic.sprites_marginbottom || 2,
  };
  delete prismic.sprites_margintop;
  delete prismic.sprites_marginbottom;
  delete prismic.body;

  return (
    <>
      <Seo
        title={prismic.title}
        description={prismic.zones[1]?.description?.text}
        image={product?.thumbnail?.originalSrc}
        preconnect={['https://cdn.shopify.com']}
      />
      <Page
        page={prismic}
        product={{
          ...product,
          url: productUrl,
        }}
      />
    </>
  );
};

export const query = graphql`
  query EshopPremium(
    $skus: [String!],
    $prismic: ID!,
    $locale: String!
    # $cmsLocale: String!
  ) {
    prismicPremium(prismicId: { eq: $prismic }) {
      data {
        title
        category
        product_link {
          url
        }
        z1_title
        z1_logo {
          alt
          url
          dimensions {
            height
            width
          }
        }
        z1_cover {
          url
          alt
          dimensions {
            height
            width
          }
        }
        z1_description {
          text
          html
        }
        z1_buy_link_name
        z1_buy_link {
          url
        }
        z2_title
        z2_link_name

        z2_link {
          url
          target
        }
        z2_buy_link_name
        z2_buy_link {
          url
        }
        z2_background {
          url
          alt
          dimensions {
            height
            width
          }
        }
        z2_points {
          text {
            html
          }
          image {
            url
            dimensions {
              height
              width
            }
            alt
          }
        }
        z3_description {
          html
        }
        z3_image {
          alt
          url
          dimensions {
            height
            width
          }
        }
        z3_link {
          url
          target
        }
        z3_link_name
        z3_buy_link_name
        z3_buy_link {
          url
        }
        z3_title
        z3_logo {
          alt
          url
          dimensions {
            height
            width
          }
        }
        z4_description
        z4_buy_link_name
        z4_buy_link {
          url
        }
        sprites_margintop
        sprites_marginbottom
        body {
          ... on PrismicPremiumDataBodySprite {
            id
            slice_type
            primary {
              image {
                alt
                dimensions {
                  width
                  height
                }
                url
              }
              hide_load
            }
            items {
              anim
              start
              end
              invert
              offset
              rotate_angle
              rotate_origin
            }
          }
        }
      }
    }
    product: shopifyProduct(skus: { in: $skus }, locale: { eq: $locale }) {
      title
      handle
      thumbnail {
        originalSrc
      }
      recommendations {
        ...ProductItem
      }
    }
  }
`;

export default PremiumPage;
