import React, { useCallback } from 'react';
import { useWindowScroll, useWindowSize, useCounter, useRaf } from 'react-use';

import csx from 'classnames';

import { Imgix, useBreakpoint } from '../../../utils';

import { Wrapper, FixedBlock, Inner } from './Animation.styles';

const responsive = {
  xxs: 11,
  md: 7,
  contained: true,
};

const computeAnimStyle = (anim, perc, style) => {
  if (!anim.anim) {
    return style;
  }

  let value = perc;

  const start = (anim.start || 0) / 100;
  const end = (anim.end || 100) / 100;
  const offset = (anim.offset || 100) / 100;

  if (value < start) {
    value = 0;
  } else if (value > end) {
    value = 1;
  } else {
    value = (value - start) / (end - start);
  }

  value = Math.max(0, Math.min(1, value));

  if (!anim.invert) {
    value = 1 - value;
  }

  // base 100
  value = value * 100;
  // Round
  value = Math.round(value * 1000) / 1000;

  if (anim.anim === 'translateY') {
    style.transform.push(`translateY(${value * offset}%)`);
  } else if (anim.anim === 'translateX') {
    style.transform.push(`translateX(${value * offset}%)`);
  } else if (anim.anim === 'rotate') {
    style.transform.push(
      `rotate(${((100 - value) * anim.rotate_angle) / 100}deg)`
    );
    style.transformOrigin = anim.rotate_origin; //'39.3% 10%';
  } else if (anim.anim === 'opacity') {
    style.opacity = value / 100;
  }

  return style;
};

const Image = React.memo(({ entry, isReady, isLoaded, perc }) => {
  const style = {
    transform: [],
    objectFit: 'contain',
  };
  entry.anims.forEach((anim) => {
    computeAnimStyle(anim, perc, style);
  });

  // console.log('**** is ready', isReady);

  if (!isReady && entry.hideLoad) {
    style.opacity = 0;
  } else if (style.opacity === undefined) {
    style.opacity = 1;
  }

  style.transform = style.transform.join(' ');

  return (
    <Imgix
      {...entry.image}
      className={csx(`sprite`, {
        under: entry.hideLoad,
      })}
      imgStyle={style}
      style={style}
      onLoad={isLoaded}
      sizes={responsive}
      loading={entry.hideLoad ? 'lazy' : 'eager'}
    />
  );
});

const Images = React.memo(({ perc, sprites = [] }) => {
  const [loaded, { inc: incLoaded }] = useCounter(0);
  const isReady = loaded >= sprites.length;

  const isLoaded = useCallback(() => {
    incLoaded();
  }, [incLoaded]);

  return sprites.map((entry, idx) => (
    <Image
      key={idx}
      entry={entry}
      perc={perc}
      isLoaded={isLoaded}
      isReady={isReady}
    />
  ));
});

const Animation = ({
  offsetTop = 62,
  sprites,
  ratio,
  margin: marginParam,
  children,
}) => {
  useRaf();
  const device = useBreakpoint();
  const ref = React.useRef(null);
  const { y } = useWindowScroll(ref);
  const window = useWindowSize();

  const elemTop = ref?.current?.offsetTop || -1;
  const elemHeight = ref?.current?.offsetHeight || -1;
  const isInline = device === 'mobile' || device === 'tablet';
  // console.log('**** ->', y);

  // useScrollPosition(({ currPos }) => {
  //   console.log('**** got pos', currPos);
  // }, []);

  if (device === 'ssr') {
    return <Wrapper ref={ref}>{children}</Wrapper>;
  }

  const margin = {
    top: Math.round((marginParam.top * window.height) / 100),
    bottom: Math.round((marginParam.bottom * window.height) / 100),
  };
  margin.total = margin.top + margin.bottom;

  // Get 50% of the width and the max of half the container
  const containerMaxWidth = 1440;
  let maxHeight = window.height - elemTop - margin.total || 0;
  if (isInline) {
    maxHeight = window.height - margin.total || 0;
  }
  const zoneWidth = (window.width * (isInline ? 100 : 50)) / 100;

  let imgWidth = Math.min(zoneWidth, containerMaxWidth / 2);
  let imgHeight = imgWidth * ratio;

  if (imgHeight > maxHeight) {
    imgHeight = maxHeight;
    imgWidth = imgHeight / ratio;
  }

  const zoneMarginRight = Math.max(0, zoneWidth - imgWidth);

  const fromTop = y - elemTop + offsetTop || -1;
  const startPerc =
    elemTop + elemHeight - (imgHeight + margin.total) - offsetTop || 0;
  const fromBottom = startPerc - y || -1;
  const perc = Math.max(Math.min((startPerc - fromBottom) / startPerc, 1), 0);
  const classes = [];

  if (isInline) {
    classes.push('inline static-top');
    margin.top = 0;
    margin.bottom = 0;
  } else {
    if (fromTop < 0) {
      classes.push('static-top');
    } else if (fromBottom < 0) {
      classes.push('static-bottom');
    }
  }

  // console.log('image finale size', imgWidth, imgHeight);
  // console.log('*** from bottom', startPerc, fromBottom);
  // console.log(rect, y, fromTop, window.height, classes.join(' '));

  return (
    <>
      {isInline ? children[0] : null}
      <Wrapper ref={ref}>
        {isInline ? children[1] : children}
        <FixedBlock
          offsetTop={offsetTop}
          className={classes.join(' ')}
          style={{
            width: imgWidth,
            height: imgHeight + margin.total,
            marginRight: zoneMarginRight,
          }}
        >
          <Inner
            style={{
              top: margin.top || 0,
              bottom: margin.bottom || 0,
            }}
          >
            <Images sprites={sprites} perc={perc} />
          </Inner>
        </FixedBlock>
      </Wrapper>
      {isInline ? children[2] : null}
    </>
  );
};

export default Animation;
