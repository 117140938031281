import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.section`
  padding: 30px 0;
  background-color: #fafafa;

  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .left {
    
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 430px;

    .logo {
      margin-bottom: 25px;
    }

    .text {
      p:first-child {
        margin-top: 0;
      }
    }
  }

  .links {
    margin-top: 10px;

    & > a {
      margin-right: 15px;
    }
  }

  @media ${device.tablet} {
    padding: 0;

    .container {
      grid-template-columns: 1fr 1fr;
      grid-gap: 10%;
    }

    .left {
      clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    }
  }
`;