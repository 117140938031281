import React from 'react';

import MagicLink from '../../../components/link';
import { Imgix, nl2br } from '../../../utils';

import { Wrapper } from './Zone3.styles';

const leftImageResponsive = {
  xs: 10,
  md: 6,
  contained: true,
};

const Zone3 = (props) => {
  const {
    description,
    image,
    link,
    link_name,
    logo,
    title,
    buy_link,
    buy_link_name,
  } = props;

  if (!image) {
    return null;
  }

  return (
    <Wrapper>
      <div className="container">
        <div className="left">
          <Imgix {...image} sizes={leftImageResponsive} autoRatio />
        </div>
        <div className="right">
          {logo ? <Imgix {...logo} height={50} className="logo" /> : null}
          <h2 className="section-title">{nl2br(title)}</h2>
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
          <div className="links">
            {!!(link?.url && link_name) && (
              <MagicLink {...link} className="simple-link black">
                {link_name}
              </MagicLink>
            )}
            {!!(buy_link?.url && buy_link_name) && (
              <MagicLink url={buy_link.url} className="simple-link red">
                {buy_link_name}
              </MagicLink>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Zone3;
