import React from 'react';
import MagicLink from '../../../components/link';
import { Imgix, nl2br } from '../../../utils';

import { Wrapper, Content } from './Zone1.styles';

const Description = ({ className, description, buy_link, buy_link_name }) => (
  <div className={className}>
    <div dangerouslySetInnerHTML={{ __html: description?.html }} />
    {buy_link?.url && (
      <MagicLink url={buy_link?.url} className="simple-link red">
        {buy_link_name}
      </MagicLink>
    )}
  </div>
);

const Zone1 = (props) => {
  const { title, description, logo, cover, buy_link, buy_link_name } = props;
  return (
    <Wrapper>
      <div style={{ position: 'relative' }}>
        <Imgix {...cover} sizes="100vw" className="cover" loading="eager" preloadHead />
        <div className="container greyzone">
          <Content className={`${logo ? 'has-logo' : ''}`}>
            <div className="shape" />
            <div className="text">
              {logo ? <Imgix {...logo} height={70} className="logo" loading="eager" /> : null}
              <h2 className="section-title">{nl2br(title)}</h2>
              <Description
                className="h-mobile"
                description={description}
                buy_link={buy_link}
                buy_link_name={buy_link_name}
              />
            </div>
          </Content>
        </div>
      </div>
      <div className="container mobile-only">
        <Description
          description={description}
          buy_link={buy_link}
          buy_link_name={buy_link_name}
        />
      </div>
    </Wrapper>
  );
};

export default Zone1;
