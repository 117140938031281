import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.section`
  display: flex;

  .left {
    padding: 30px 0;

    .section-title {
      margin-top: 0;
    }
  }

  .points {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    .point {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    .image {
      margin-bottom: 10px;

      img {
        object-fit: contain;
        object-position: center;
      }
    }

    .text {
      font-size: 14px;

      p {
        margin: 0;
      }
    }
  }

  .links {
    margin-top: 30px;

    & > a {
      margin-right: 15px;
    }
  }

  .right {
    position: relative;
    width: 50%;
    display: none;

    .background {
      position: absolute !important;
      left: 0;
      right: 0;
      bottom: 5%;
      top: 10%;

      img {
        object-fit: contain;
        object-position: left center;
        height: 100%;
      }
    }
  }

  

  @media ${device.laptop} {
    .left {
      width: 45%;
      margin-right: 5%;
      padding: 80px 0;
    }

    .right {
      display: block;
    }

    .points {
      width: 100%;
    }
  }

  @media ${device.laptopM} {
    .left {
      padding: 180px 0;
    }
    .points {
      .point {
        flex-direction: row;
        text-align: left;

        .image {
          margin-bottom: 0;
          margin-right: 20px;
        }
      }
    }
  }
`;
