import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  /* border: 1px solid #F0F; */
`;

export const FixedBlock = styled.div`
  position: fixed;
  top: ${(p) => p.offsetTop || 0}px;
  right: 0;
  display: block;
  z-index: 10;
  overflow: hidden;

  &.inline {
    left: 0;
  }

  &.static-top {
    position: absolute;
    top: 0;
  }

  &.static-bottom {
    position: absolute;
    top: auto;
    bottom: 0;
  }

  &.sticky {
  }

  .sprite {
    position: absolute !important;
    top: 0;
    width: 100%;

    &.under {
      img {
        transition-delay: 100ms !important;
      }
    }

    img {
      backface-visibility: hidden;
      transition: opacity 100ms !important;
    }
  }
`;

export const Inner = styled.div`
  position: absolute;
  width: 100%;
`;
