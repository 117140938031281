import React from 'react';

import Header from './Header';
import Zone1 from './Zone1';
import Zone2 from './Zone2';
import Zone3 from './Zone3';
import Zone4 from './Zone4';
import Animation from './Animation';

import { ProductRecommendations } from '../../../components/eshop';

import { Wrapper, MobilePlaceholder } from './Page.styles';

const Page = ({ product, page }) => {
  return (
    <Wrapper>
      <Header
        title={page.title}
        category={page.category}
        productUrl={page.product_link?.url || product.url}
        innerZ={100}
      />
      <Animation
        sprites={page.sprites}
        ratio={page.sprites_ratio}
        margin={page.sprites_margin}
      >
        <Zone1 key="zone1" {...page.zones[1]} productUrl={product.url} />
        <MobilePlaceholder />
        <Zone2 key="zone2" {...page.zones[2]} productUrl={product.url} />
      </Animation>
      <Zone3 key="zone3" {...page.zones[3]} productUrl={product.url} />
      <Zone4
        key="zone4"
        title={page.title}
        {...page.zones[4]}
        productUrl={product.url}
      />
      <div className="container">
        <ProductRecommendations
          recommendations={product.recommendations}
          showTitle
        />
      </div>
    </Wrapper>
  );
};

export default Page;
